
export const RendererOptions = {
    viewOptions: {
        minZoom: 0.0075,
        maxZoom: 8.0,
    },
    edgeHandleOptions: {
        screenSpace: true,
        radius: 4.0,
        segments: 12,
        fillColor: '#cccccc',
        strokeWeight: 1.0,
        strokeColor: '#ffcc44',
    },
    vertexHandleOptions: {
        screenSpace: true,
        radius: 4.0,
        segments: 12,
        fillColor: '#ffffff',
        strokeWeight: 1.0,
        strokeColor: '#ffcc44',
    },
    edgeLabelOptions: {
        textSize: 9,
        textSegments: 4,
        textFG: '#ffffff',
        textBG: '#222222',
        bgPadding: 1.0,
        offset: 8.0,
        textureFontScaling: 0.36,
    },
    surfaceLabelOptions: {
        textSize: 11,
        textSegments: 4,
        textFG: '#ffffff',
        textBG: '#444444',
        bgPadding: 7.0,
        offset: 8.0,
        textureFontScaling: 0.54,
    },
    createPathOptions: {
        cursorRadius: 4.0,
        cursorSegments: 12,
        cursorColor: '#44ff44',
        openPathColor: '#ffaa44',
        closePathColor: '#44ff44',
        pathWeight: 2.0,
        pathOpacity: 1.0,
    },
    moduleControlOptions: {
        moduleOutlineColor: '#ffaa22',
        selectionOutlineColor: '#44ff44',
    },
};

export default RendererOptions;
